// The Vuetify setup was mostly copied from: https://github.com/logue/vite-vuetify-ts-starter/tree/c12f578d6ecf2934112eb18737b475051353b7e1.

import { aliases, mdi } from 'vuetify/iconsets/mdi' // Material Design icons
import { fa } from 'vuetify/iconsets/fa' // Font Awesome icons
import { createVuetify } from 'vuetify'
import { md3 } from 'vuetify/blueprints'

// For test use. Do not include createVuetify()
// see https://next.vuetifyjs.com/en/features/treeshaking/
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Translations provided by Vuetify
import { en } from 'vuetify/locale'

// Misc
import { loadFonts } from './webfontloader'
loadFonts();

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

// TODO: Move to another file? Create more themes?
const customLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    yellow: '#E7E000',
    black: '#000000'
  }
}

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      fa
    }
  },
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en }
  },
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme
    }
  },
  blueprint: md3,
})

// Export for test.
export { components, directives }
